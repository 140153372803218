<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/employeeInfo">员工信息</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="单位" prop="companyId">
        <el-select size="mini" v-model="queryModel.companyId" filterable placeholder="请选择">
          <el-option
            v-for="company in companyList"
            :key="company.id"
            :label="company.name"
            :value="company.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名" prop="employeeName">
        <el-input type="text" size="mini" v-model="queryModel.employeeName"></el-input>
      </el-form-item>
      <el-form-item label="导出状态" prop="exportStatus">
        <el-select
          size="mini"
          v-model="queryModel.exportStatus"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option :label="'已导出'" :value="true"></el-option>
          <el-option :label="'未导出'" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length==0"
        @click="downConfirm"
      >打包下载员工资料</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-delete"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column prop="companyName" label="所属单位" sortable="custom" width="180"></el-table-column>
      <el-table-column prop="name" sort-by="name_" label="姓名" width="100"></el-table-column>
      <el-table-column prop="photo" label="照片" width="100">
        <template slot-scope="{row}">
          <a :href="row.photo" target="_blank">
            <el-avatar
              size="large"
              fit="cover"
              shape="circle"
              :src="row.photo+'?x-oss-process=image/resize,m_lfit,w_100'"
              :key="row.id"
            ></el-avatar>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="exportStatus"
        sort-by="export_status"
        label="导出状态"
        sortable="custom"
        width="100"
      >
        <template slot-scope="{row}">{{row.exportStatus ? "已导出" : "未导出"}}</template>
      </el-table-column>
      <el-table-column prop="openId" label="openId" width="200"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="150"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间" width="150"></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <el-dialog
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-form>
        <el-form-item label="压缩包解压后文件夹所在路径" prop="downPath">
          <el-input v-model="downPath" placeholder="请输入所在路径" style="width:100%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="downloadZip">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import employeeInfoApi from "@/api/base/employeeInfo";
import companyInfoApi from "@/api/base/companyInfo";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        companyId: "",
        employeeName: "",
        exportStatus: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 100,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [100, 200, 300, 500],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      companyList: [],
      dialogFormVisible: false,
      downPath: "C:"
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("employeeName", self.queryModel.employeeName);
      formData.append("companyId", self.queryModel.companyId);
      formData.append("exportStatus", self.queryModel.exportStatus);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      employeeInfoApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        employeeInfoApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    queryCompanyList() {
      var self = this;
      companyInfoApi
        .list()
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data;
          if (jsonData.result) {
            self.companyList = jsonData.data;
          } else {
            this.$message.error(jsonData.message + "");
          }
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    downConfirm() {
      this.dialogFormVisible = true;
    },
    downloadZip() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });
      var localPath = self.downPath;
      if (localPath == null || localPath.length == 0) {
        localPath = "C:";
      }
      employeeInfoApi.downloadZip(idList, localPath);
    }
  },
  mounted: function() {
    this.changePage(1);
    this.queryCompanyList();
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>